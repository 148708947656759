import React from 'react';
import { BenefitsSection } from 'src/sections';
import { Page, Section, SmolBlock, Button, Grid } from 'src/components';
import paths from 'src/constants/paths';

const getStarted = [
  {
    icon: 'arrow-right',
    title: 'You don’t have to write any new code.',
    subtitle: 'Get started quickly and work with Catch to enrich the experience over time',
  },
  {
    icon: 'bank',
    title: 'We support your existing flow of funds.',
    subtitle: 'You won’t need to change anything about your setup',
  },
  {
    icon: 'download-cloud',
    title: 'Users can import and transfer marketplace plans',
    subtitle: 'From standalone marketplace sites like HealthCare.gov, Stride, and others',
  },
  {
    icon: 'navigation',
    title: 'We work with partners to craft our roadmap.',
    subtitle: 'Together with users and internal experiments',
  },
];

const customizations = [
  {
    image: 'paintbucket',
    title: 'Custom Setup.',
    subtitle: 'Easy onboarding built exclusively for your platform',
  },
  {
    image: 'color-swatch',
    title: 'Cobranding.',
    subtitle: 'Brand positioning in product and communications',
  },
  {
    image: 'announcement',
    title: 'Comms.',
    subtitle: 'Dedicated communications to help your people every step of the way',
  },
  {
    image: 'book',
    title: 'Content.',
    subtitle: 'Financial education to make complicated decisions a little easier',
  },
];

const trustItems = [
  {
    icon: 'shield-tick',
    text: 'Experts in regulatory compliance for financial services, insurance, and benefits',
  },
  { icon: 'passport', text: 'Licensed insurance agency for multiple lines' },
  {
    icon: 'clipboard',
    text: 'Audited annually for 400+ privacy and security controls',
  },
];

const contactButton = (
  <Button large to={paths.CONTACT}>
    Get in touch
  </Button>
);

const PartnershipPage = () => {
  return (
    <Page
      path={paths.PARTNERS}
      title="Partner with the only portable benefits platform"
      description="Help your contractors, employees, and members save time and money on taxes, health insurance and retirement."
      prefooter={<></>}
      image="/img/og/default.png"
    >
      <Section
        pretitle="Partner with Catch"
        center
        nav
        large
        wide
        title="Benefits that make the difference"
        subtitle="Help independent contractors on your platform take care of health, dental and more automatically. At no cost to you."
      >
        <div className="pad-y2">{contactButton}</div>
      </Section>

      <BenefitsSection
        small
        title="The only all-in-one portable benefits platform"
        subtitle="Everything independents need in one easy-to-use platform"
      />

      <Section
        title="Trusted by independents, treasured by partners"
        bg="var(--g-orange-light)"
        inset
      >
        <Grid num={2}>
          {customizations.map((customization, idx) => (
            <SmolBlock
              key={idx}
              icon={`/iconic/${customization.image}.svg`}
              title={customization.title}
              text={customization.subtitle}
            />
          ))}
        </Grid>
      </Section>

      <Section title="It's free and easy to get started">
        <Grid num={2}>
          {getStarted?.map((item, idx) => (
            <SmolBlock
              key={idx}
              color="var(--g-orange-light)"
              icon={`/iconic/${item.icon}.svg`}
              title={item.title}
              text={item.subtitle}
            />
          ))}
        </Grid>
      </Section>

      <Section inset dark bg="black" title="Compliance, Privacy, Security">
        <Grid num={2}>
          {trustItems?.map((item, idx) => (
            <SmolBlock key={idx} icon={`/iconic/${item.icon}.svg`} title={item.text} text={' '} />
          ))}
        </Grid>
      </Section>

      <Section
        bg="var(--g-cerise-text)"
        center
        inset
        dark
        narrow
        title={<>Get Started with a Catch&nbsp;Partnership</>}
      >
        {contactButton}
      </Section>
      <br />
    </Page>
  );
};

export default PartnershipPage;
